import React from 'react'
import PropTypes from 'prop-types'
import { get, isEqual, reduce, min, max } from 'lodash'

import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const getRoomsString = (rooms) => {
  if (rooms < 2) {
    return `${rooms} pièce`
  }

  return `${rooms} pièces`
}

const Rooms = ({ program, rooms, ...props }) => {
  const { maxRooms, minRooms } = reduce(rooms, (acc, room) => ({
    minRooms: min([get(acc, 'minRooms'), room]),
    maxRooms: max([get(acc, 'maxRooms'), room])
  }), { minRooms: 999, maxRooms: 0 })

  const single = isEqual(get(program, 'lot_count', 1), 1)

  if (isEqual(maxRooms, minRooms)) {
    return (
      <HomeSectionDescription {...props}>
        {single ? 'Local' : 'Locaux'} de {getRoomsString(minRooms)}
      </HomeSectionDescription>
    )
  }

  return (
    <HomeSectionDescription {...props}>
      Locaux de {minRooms} à {maxRooms} pièces
    </HomeSectionDescription>
  )
}

Rooms.propTypes = {
  rooms: PropTypes.array.isRequired,
  program: PropTypes.object.isRequired
}

export default Rooms
