import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map, isEmpty } from 'lodash'

import media from '../../../utils/media'
import ProgramMap from '../../molecules/ProgramMap'
import ProgramLots from '../../molecules/ProgramLots'
import ProgramForm from '../ProgramForm'
import ProgramData from '../../molecules/ProgramData'
import ProgramTitle from '../../molecules/ProgramTitle'
import LoadingProgram from '../../atoms/LoadingProgram'
import ProgramCarousel from '../../molecules/ProgramCarousel'
import ProgramDescription from '../../molecules/ProgramDescription'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 0 56px 0;
  justify-content: center;

  ${media.lessThan('xl')`
    flex-direction: column;
    align-items: center;
    // padding: 20px 8px;
  `}
`

const ProgramContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 54px;

  ${media.lessThan('xl')`
    margin: 0;
    width: 100%;
  `}
`

const ItemContainer = styled.div`
  padding: 0 24px;

  ${media.lessThan('xl')`
    &:first-of-type {
      padding-top: 64px;
    }
  `}
`

const DesktopItemContainer = styled(ItemContainer)`
  display: block;

  ${media.lessThan('xl')`
    display: none;
  `}
`

const MobileItemContainer = styled(ItemContainer)`
  display: none;

  ${media.lessThan('xl')`
    margin-top: 24px;
    display: block;
  `}
`

const ContentContainer = styled.div`
    width: 800px;
    margin-top: 40px;

    ${media.lessThan('sm')`
      margin-top: 0;
    `}
`

const ProgramContent = ({ loading, program }) => {
  if (loading) {
    return (
      <LoadingProgram loading={loading} />
    )
  }

  const lots = get(program, 'lots')
  const city = get(program, 'city')
  const name = get(program, 'name')

  return (
    <ContentContainer>
      <DesktopItemContainer>
        <ProgramTitle
          city={city}
          name={name} />
      </DesktopItemContainer>
      <ProgramCarousel id={get(program, 'id', undefined)} imgs={map(get(program, 'images'), 'url')} />
      <MobileItemContainer>
        <ProgramTitle
          city={city}
          name={name} />
      </MobileItemContainer>
      <ItemContainer>
        <ProgramData
          lots={lots} />
      </ItemContainer>
      {!isEmpty(get(program, 'description')) && <ItemContainer>
        <ProgramDescription description={get(program, 'description', '')} />
      </ItemContainer>}
      <ItemContainer>
        <ProgramMap
          city={city}
          address={get(program, 'address')}
          addressText={get(program, 'address.text')} />
      </ItemContainer>
      <ItemContainer>
        <ProgramLots
          city={get(program, 'city.name')}
          lots={lots}
          program={program} />
      </ItemContainer>
    </ContentContainer>
  )
}

ProgramContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  program: PropTypes.object
}

const ProgramPage = ({ program, loading }) => {
  return (
    <Container>
      <ProgramContentContainer>
        <ProgramContent program={program} loading={loading} />
        <ProgramForm
          program={program}
          programs={program && [program]}
          loading={loading} />
      </ProgramContentContainer>
    </Container>
  )
}

ProgramPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  program: PropTypes.object
}

export default ProgramPage
