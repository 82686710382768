import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { get, map, isEmpty, size, isEqual, reduce, find } from 'lodash'

import media from '../../../utils/media'
import Rooms from '../../atoms/Rooms'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  margin-top: 26px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  padding: 24px 16px;
  border-radius: 10px;

  ${media.lessThan('sm')`
    padding: 8px;
    flex-direction: column;
    justify-content: center;
  `}
`

const DataPart = styled.div`
  display: flex;
  align-items: center;
  margin-right: 28px;

  ${media.lessThan('sm')`
    margin-top: 16px;
    margin-right: 0;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const ProgramData = ({ lots }) => {
  const extras = reduce(lots, (acc, lot) => [...acc, ...get(lot, 'extras', [])], [])
  const parking = isEmpty(extras) ? false : find(extras, extra => isEqual(get(extra, 'type'), 'parking'))

  return (
    <Container>
      <DataPart>
        <ImageContainer>
          <StaticImage style={{ width: '30px', height: '30px' }} src='../../../images/picto/house.png' alt='livraison programme' />
        </ImageContainer>
        <HomeSectionDescription>Programme neuf</HomeSectionDescription>
      </DataPart>
      <DataPart>
        <ImageContainer>
          <StaticImage src='../../../images/picto/surface.png' alt='surface programme' />
        </ImageContainer>
        <Rooms program={{ lot_count: size(lots) }} rooms={map(lots, lot => get(lot, 'rooms'))} />
      </DataPart>
      {parking && <DataPart>
        <ImageContainer>
          <StaticImage style={{ width: '40px', height: '40px' }} src='../../../images/picto/parking.png' alt='livraison programme' />
        </ImageContainer>
        <HomeSectionDescription>Parking</HomeSectionDescription>
      </DataPart>}
    </Container>
  )
}
ProgramData.propTypes = {
  lots: PropTypes.array,
  taxation: PropTypes.string,
  delivery: PropTypes.string
}

ProgramData.defaultProps = {
  lots: [],
  taxation: '',
  delivery: ''
}

export default ProgramData
