import React, { useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isEqual, groupBy, get, map, first, tail, min, max, reduce, size } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '../../atoms/Modal'
import media from '../../../utils/media'
import Rooms from '../../atoms/Rooms'
import theme from '../../../config/theme'
import numeral from '../../../config/number'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import LotForm from '../../organisms/LotForm'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`

const RowContainer = styled.div`
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', '#fff')};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:first-of-type {
    margin-top: 16px;
  }

  ${media.lessThan('md')`
  padding: 12px 8px;
`}
`

const Line = styled.span`
  ${media.lessThan('md')`
    display: none;
  `}
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;

  ${({ flex }) => `justify-content: ${flex}` || ''};

  ${media.lessThan('md')`
    font-size: 16px;
    flex-direction: column;
    gap: 2px;
    align-items: baseline;
  `}
`
const Infos = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
`
const StyledRooms = styled(Rooms)`
  font-weight: 600;
  font-size: 20px;

  ${media.lessThan('md')`
  font-size: 16px;
`}
`
const ButtonContainer = styled.div`
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  border-radius: 24px;
  text-align: center;
  height: fit-content;
  padding: 16px 12px;
  font-weight: 600;
  cursor: pointer;

  ${media.lessThan('md')`
    font-size: 14px;
    border-radius: 4px;
    flex: 1;
    max-width: 120px;
  `}
`
const StyledRow = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LotFormContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyleSubtitle = styled(HomeSectionDescription)`
  max-width: 360px;
  margin-bottom: 4px;
`

const InlineHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 16px;
`

const LotRow = ({ program, lots }) => {
  const [open, updateOpen] = useState(false)
  const hiddenRef = useRef()
  const { maxSurface, minSurface } = reduce(map(lots, lot => get(lot, 'surface')), (acc, surface) => ({
    minSurface: min([get(acc, 'minSurface'), surface]),
    maxSurface: max([get(acc, 'maxSurface'), surface])
  }), { minSurface: 999, maxSurface: 0 })
  const lowest = reduce(tail(lots),
    (acc, { prices }) => min([acc, min(map(prices, 'total'))]), min(map(get(first(lots), 'prices'), 'total')))
  const surface = `${isEqual(maxSurface, minSurface) ? minSurface : `de ${minSurface}m2 à ${maxSurface}`}m2`
  const handleOpenModal = useCallback(() => updateOpen(!open), [open, updateOpen])
  const handleCloseModal = useCallback(() => updateOpen(false), [updateOpen])

  const room = get(first(lots), 'rooms')

  return (
    <StyledRow>
      <Infos>
        <Inline>
          <StyledRooms program={{ lot_count: size(lots) }} rooms={map(lots, lot => get(lot, 'rooms'))} />
          <Line>-</Line>
          <span>{surface}</span>
        </Inline>
        <HomeSectionDescription color='brightOrange'>
          A partir de {numeral(lowest).format('0,0 $')}
        </HomeSectionDescription>
      </Infos>
      <ButtonContainer onClick={handleOpenModal}>
        <span>demande d&apos;informations</span>
      </ButtonContainer>
      <Modal isOpen={open} onRequestClose={handleCloseModal}>
        <LotFormContainer>
          <InlineHeader>
            <HomeSectionSubtitle>
              Demande d&apos;informations pour les locaux de <br />{room} pièces
              du programme {get(program, 'name', '')}
            </HomeSectionSubtitle>
            <FontAwesomeIcon
              onClick={handleCloseModal}
              color={get(theme, 'blue')}
              icon='times' />
          </InlineHeader>
          <StyleSubtitle>
            Pour échanger avec le conseiller en charge de la vente de ces locaux,<br />merci de laisser vos coordonnées :
          </StyleSubtitle>
          <LotForm
            add={{
              lotRooms: room,
              programId: get(program, 'id'),
              programName: get(program, 'name')
            }}
            hiddenRef={hiddenRef}
            redirection='/nos-annonces/program/lot-success'
            submit='https://hooks.zapier.com/hooks/catch/2114713/3r2pogt/' />
        </LotFormContainer>
      </Modal>
    </StyledRow>
  )
}

LotRow.propTypes = {
  lots: PropTypes.array.isRequired,
  program: PropTypes.object
}

const ProgramLots = ({ lots, program }) => {
  const groupByRooms = groupBy(lots, 'rooms')
  const single = size(lots) < 2

  return (
    <Container>
      <HomeSectionTitle>
        {single ? 'Local' : 'Locaux'} disponible{single ? '' : 's'} à la vente
      </HomeSectionTitle>
      {map(groupByRooms, elt => {
        return (
          <LotRow key={get(first(elt), 'rooms')} lots={elt} program={program} />
        )
      })}
    </Container>
  )
}

ProgramLots.propTypes = {
  lots: PropTypes.array,
  city: PropTypes.string,
  program: PropTypes.object
}

ProgramLots.defaultProps = {
  lots: [],
  city: '',
  program: {}
}

export default ProgramLots
